@font-face {
  font-family: 'forza';
  src: url('./Forza-Light.woff2') format('woff2'),
    url('./Forza-Light.woff') format('woff'),
    url('./Forza-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'forza';
  src: url('./Forza-Book.woff2') format('woff2'),
    url('./Forza-Book.woff') format('woff'),
    url('./Forza-Book.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'forza';
  src: url('./Forza-Medium.woff2') format('woff2'),
    url('./Forza-Medium.woff') format('woff'),
    url('./Forza-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'forza';
  src: url('./Forza-Bold.woff2') format('woff2'),
    url('./Forza-Bold.woff') format('woff'),
    url('./Forza-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'forza';
  src: url('./Forza-Bold.woff2') format('woff2'),
    url('./Forza-Bold.woff') format('woff'),
    url('./Forza-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
